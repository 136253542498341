import React, { useContext } from "react";
import { MainContext } from "../Context";

const SignInForm = (props) => {
  const {
    handleLogIn,
    setEmail,
    setPassword,
    errorMessage,
    setErrorMessage,
  } = useContext(MainContext);

  const inputEmail = (e) => setEmail(e.target.value);
  const inputPassword = (e) => setPassword(e.target.value);

  const onLogin = (e) => {
    handleLogIn(e);
  };

  return (
    <div className="sign-in-form">
      <h4>Sign In</h4>
      <form className="form-group" action="">
        Email{" "}
        <input className="form-control" onChange={inputEmail} type="email" />
        Password{" "}
        <input
          className="form-control"
          onChange={inputPassword}
          type="password"
        />
        <button type="button" onClick={onLogin} className="btn btn-primary">
          login
        </button>
        <button type="button" onClick={props.close} className="btn btn-light">
          cancel
        </button>
      </form>
      {errorMessage && (
        <div className="error-message">
          <p>
            {errorMessage}
            <br />
            <small
              style={{ cursor: "pointer" }}
              onClick={() => setErrorMessage(null)}
            >
              close
            </small>
          </p>
        </div>
      )}
    </div>
  );
};

export default SignInForm;
