import React, {useState, useContext} from 'react'
import {MainContext} from '../Context'
import {Modal} from 'react-bootstrap'

const SignUpForm = ({close}) => {
  const {errorMessage, setErrorMessage, name, email, password, pic, setName, setEmail, setPassword, setPic, createUser} = useContext(MainContext)
const [modal, setModal] = useState(false)
    const handleNameInput = e => setName(e.target.value)
    const handleEmailInput = e => setEmail(e.target.value)
    const handlePasswordInput = e => setPassword(e.target.value)
    const handlePic = e => setPic(e.target.files[0])
   
  

    return (
        <div className="sign-in-form" >
            <h4 className="text-center" >Register Here</h4>
            <p style={{textAlign:'center'}} >Registering allows you to post reviews, build a list of 'Favourites' or list your business.</p>
            <form className="form-group signup-form" action="">
                Profile Name <br/><small> (NB your own name, rather than your business. It will appear on any reviews or business listings you make.)</small>
            <input value={name} className="form-control form-input" onChange={handleNameInput} type="text"/>
            Email
            <input value={email} className="form-control form-input" onChange={handleEmailInput} type="email"/>
            Password
            <input value={password} className="form-control form-input" onChange={handlePasswordInput} type="password"/>
            Profile Image <br/> <small> (this will be displayed on any businesses you list)</small>
            <input value={pic} className="form-control form-input" onChange={handlePic} type="file"/>
<small>by clicking register, you agree to our <br/> <span style={{cursor:'pointer', color:'green'}} onClick={() => setModal(true)}>terms & conditions and GDPR policy.</span></small>
<br/>
            <button style={{marginTop:25}} className="btn btn-primary" type="button" onClick={createUser} >register</button>
            <button style={{marginTop:25}} className="btn btn-light" type="button" onClick={close} >close</button>
            </form>
            {errorMessage && (
        <div className="error-message">
          <p>
            {errorMessage}
            <br />
            <small
              style={{ cursor: "pointer" }}
              onClick={() => setErrorMessage(null)}
            >
              close
            </small>
          </p>
        </div>
      )}
<div>
    
</div>
<Modal show={modal} >
  <div style={{padding:30, textAlign:'justify'}} >
    <h5>Terms & Conditions</h5>
    <p>The OssiaNet concept, website and any subsqeuent applications exists for the use of, and for the benefit of musicians, performance artists and others who are now 
      starting small businesses and freelance work to replace lost income through the effects of the COVID 19 pandemic.</p>
      <p>Any member of the public may access the directory and create an account to use cerain features such as writing reviews or adding favourites, but we ask that only those to whom the above applies
        list their businesses, which may be of any nature.  OssiaNet reserves the right to remove without warning any content or user posted which is deemed inappropriate. </p>
    <h5>General Data Protection Regulation</h5>
    <p>It's YOUR Personal Data</p>
<p>Here at OssiaNet we take your privacy seriously and will only use your personal information to respond to any questions you may have and to provide the products and services stated on this website. <br/> Your personal information will never be shared with third parties for marketing purposes and will not be used by us for marketing purposes without your explicit consent.
<p>OssiaNet is copyrighted 2021, Adam Woolf.</p>
 <hr/>

<h6>Data Collected on This Website</h6>

By continuing to use this website, you accept that we use the following cookies:

Google Fonts
We use Google Fonts API to provide the font faces we use on this website.

When you use our website, some data, including your IP address, will be transferred to and stored on Google’s servers in the USA. This data is used solely for the purpose of providing the service, and is not associated with other data Google may hold on you. 
<br/>
<br/>
The use of Google Maps API and Google Fonts API, as well as the relevant data transfer and processing is governed by Google Inc.’s data protection policy: http://www.google.com/policies/privacy/.
<hr/>
Your rights
In accordance with UK and European law under the General Data Protection Regulation (GDPR), you have numerous rights in regards to the personal data we hold about you, including:
<br/>
<ul>
<li>Right to confirmation – you have the right to know if we hold personal data that concerns you.</li>
<li>Right to access – you have the right to view and to obtain a copy of any personal data we hold that concerns you. </li>
<li>Right to rectification – you have the right to the correction of any inaccuracies within the personal data we hold that concerns you. </li>
<li>Right to erasure – you have the right to have your personal data removed from our systems</li>
</ul>
</p>

<button type="button" onClick={() => setModal(false)} className="btn btn-light btn-sm">close</button>
  </div>
</Modal>
        </div>
    )
}

export default SignUpForm
