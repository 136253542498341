import React, {useContext, useState} from 'react'
import {MainContext} from '../Context'
import AboutMe from './AboutMe'
import CustomButton from './CustomButton'

const DonateButton = () => {
  const {signout, user} = useContext(MainContext)
  const [modal, setModal] = useState(false)
    return (
        <div style={{ marginBottom: "20px" }} className="text-center">
            <small>OssiaNet is a platform for everyone. It helps freelancers build their
              businesses and people of the earth to find great products and services. <br/>
              It is free to use, but if you would like to make a donation towards 
              development and running costs, please use this button.  <br/>
              Any amount is gratefully recieved.
  </small>
        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_blank"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input
            type="hidden"
            name="hosted_button_id"
            value="WE8ZFX6GSFUD2"          />
          {/* <button
             name="submit"
            title="PayPal - The safer, easier way to pay online!"
            className="btn btn-primary"
            value="donate"
          >donate with PayPal</button> */}
          <CustomButton text="donate with paypal" click="submit" ></CustomButton>
       
        </form>
        <div style={{marginTop:40}}>
        <button onClick={() => setModal(true)} className="btn btn-light btn-sm" type="button">about the developer</button>
      {user &&  <button  type="button" onClick={signout} className="btn btn-light btn-sm"  >sign out</button>}
      </div>
      <AboutMe show={modal} close={() => setModal(false)}  />
      </div>
    )
}

export default DonateButton
