import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "../../Context";
import { Row, Col } from "react-bootstrap";
import {
  FaThumbsUp,
  FaHeart,
  FaEnvelope,
  FaPhoneAlt,
  FaDesktop,
  FaShareAlt,
} from "react-icons/fa";
import DetailsModal from "./DetailsModal";
import firebase from "../../firebase";
import CustomButton from "../CustomButton";

const BusinessCard = ({ trader, index, likes }) => {
  const { user, toggleFavs, favIds } = useContext(MainContext);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedTrader, setSelectedTrader] = useState([]);
  const [reviews, setReviews] = useState([]);

  const showSelected = (t) => {
    setDetailsModal(true);
    const selected = t;
    setSelectedTrader(selected);
  };

  const getReviews = () => {
    firebase
      .database()
      .ref(`traders/${trader.k}/reviews`)
      .on("value", (snapshot) => {
        const reviews = snapshot.val();
        let keys = [];
        if (reviews) {
          keys = Object.keys(reviews);
        }
      
        let array = [];

        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          let id = reviews[k].id;
          let by = reviews[k].by;
          let content = reviews[k].content;
          let submitted = reviews[k].submitted;
          let email = reviews[k].email;
          array.push({ key: k, id, by, content, submitted, email });
          console.log(array);
          setReviews(array.reverse());
        }
      });
  };

  useEffect(() => {
    getReviews();
  }, []);

  const reviewsToPrint = [...reviews];
  return (
    <div className="business-card">
      <div className="business-card-left">
        <img
          src={trader.logo}
          alt=""
          style={{ maxHeight: "150px", maxWidth: "100px" }}
        />

        <div>
          {trader.brand && (
            <div>
              <h4>{trader.brand}</h4>
              <h5>{trader.name}</h5>
            </div>
          )}
          {!trader.brand && <h4>{trader.name}</h4>}
        </div>

        <p>
          <em>{trader.service}</em>
          <br />

          <small style={{ color: "grey" }}>
            {trader.city}, {trader.country} <br />{" "}
            {trader.online && (
              <span
                style={{
                  color: "green",
                  border: "1px solid green",
                  padding: 3,
                  marginLeft: 4,
                }}
              >
                Working Worldwide{" "}
              </span>
            )}
          </small>
        </p>
      </div>

      <div>
        <div>
          {trader.email && (
            <a href={`mailto:${trader.email}`}>
              <FaEnvelope /> {trader.email}
            </a>
          )}
          <br />
          {trader.tel && (
            <a href={`tel:${trader.tel}`}>
              <FaPhoneAlt /> {trader.tel}
            </a>
          )}
          <br />
          {trader.website.includes("http") ? (
            <a target="_blank" rel="noreferrer" href={trader.website}>
              <FaDesktop /> visit website
            </a>
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href={`http://${trader.website}`}
            >
              <FaDesktop /> visit website
            </a>
          )}
        </div>
        {reviews.length > 0 && (
          <small style={{ marginRight: 15, color: "grey", marginBottom: -5 }}>
            {reviews.length} reviews
          </small>
        )}

        <div className="business-card-buttons">
          <div style={{width:'200px'}}>
            <CustomButton text="more info" click={() => showSelected(trader)} />
            {/* <button
              onClick={() => showSelected(trader)}
              style={{ width: 220 }}
              className="btn btn-primary btn-sm"
              type="button"
            >
              More info
            </button> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            {user && (
              <a
                style={{
                  marginRight: 10,
                  cursor: "pointer",
                  color: "rgb(45, 140, 140)",
                }}
                href={`mailto:?subject=I${trader.brand}%20by%20${trader.name}&body=Hi!%0D%0DI%20thought%20you%20might%20be%20interested%20in%20this%20great%20enterprise%20I%20discovered%20on%20www.ossianet.com%2E%0D%0DCheck%20out%20${trader.name}%27s%20activites%20at%20${trader.website}%2C%20or%20drop%20them%20a%20line%20at%20${trader.email}%2E%0D%0Dbest%20wishes%2C%0D${user.displayName}`}
              >
                <FaShareAlt />
              </a>
            )}
            {/* {trader.likes === 0 && (
              <FaThumbsUp
                style={{ cursor: "pointer" }}
                onClick={() => likes(trader, index)}
              />
            )}
            {trader.likes > 0 && (
              <span style={{ color: "green", cursor: "pointer" }}>
                <div style={{ display: "inline-block" }}>
                  {" "}
                  <FaThumbsUp onClick={() => likes(trader, index)} />{" "}
                  {trader.likes}
                </div>
              </span>
            )} */}
            {user && (
              <button
                onClick={() => toggleFavs(trader.k)}
                className="btn btn-light"
              >
                <FaHeart color={favIds.includes(trader.k) ? "red" : "grey"} />
              </button>
            )}
          </div>
        </div>
      </div>
      <DetailsModal
        index={index}
        toggleFavs={toggleFavs}
        favIds={favIds}
        reviewsToPrint={reviewsToPrint}
        user={user}
        reviews={reviews}
        handleLikeIncrement={likes}
        t={selectedTrader}
        show={detailsModal}
        close={() => setDetailsModal(false)}
      />
    </div>
  );
};

export default BusinessCard;
