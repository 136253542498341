import firebase from 'firebase';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "phoenix-8028d.firebaseapp.com",
    databaseURL: "https://phoenix-8028d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "phoenix-8028d",
    storageBucket: "phoenix-8028d.appspot.com",
    messagingSenderId: "840939481828",
    appId: "1:840939481828:web:6a4f93de8d9f6d15549af1",
    measurementId: "G-PM4MXV3RD3"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
export default firebase;