import React, { useContext } from "react";
import { MainContext } from "../Context";

const BusinessRegistrationPage = (props) => {
  const {
    traderName,
    setTraderName,
    traderStory,
    setTraderStory,
    handleTraderImageFile,
    traderService,
    setTraderService,
    traderTel,
    setTraderTel,
    traderCity,
    setTraderCity,
    setTradingOnline,
    traderWebsite,
    setTraderWebsite,
    traderCountry,
    setTraderCountry,
    traderEmail,
    setTraderEmail,
    registerTrader,
    setCategory,
    setIsProduct,
    setIsService
  } = useContext(MainContext);

  const handleNameInput = (e) => setTraderName(e.target.value);
  const handleTraderEmail = (e) => setTraderEmail(e.target.value);
  const handleTraderService = (e) => setTraderService(e.target.value);
  const handleTraderCity = (e) => setTraderCity(e.target.value);
  const handleTraderCountry = (e) => setTraderCountry(e.target.value);
  const handleTraderWebsite = (e) => setTraderWebsite(e.target.value);
  const handleTraderTel = (e) => setTraderTel(e.target.value);
  const handleTraderStory = (e) => setTraderStory(e.target.value);
  const handleOnline = (e) => setTradingOnline(true);
  const handleCategory = (e) => setCategory(e.target.value);
  const inputProduct = e => setIsProduct(true)
  const inputService = e => setIsService(true)



  return (
    <div>
      <div className="registration-modal">
      <h4>Register Here</h4>
      <p>You may register multiple businesses.</p>
<hr/>
        <h5>Tell us about your business or service:</h5>
        <form className="form-group" action="">
          Your business name or brand:{" "}
          <input
            value={traderName}
            placeholder="leave blank if this is the same as your chosen username"
            onChange={handleNameInput}
            className="form-control"
            type="text"
          />
          <hr/>
          Add a logo{" "}
          <input
            onChange={handleTraderImageFile}
            type="file"
            required
          /><br/>
          <hr/>
          What do you offer:{" "}
          <textarea
            value={traderService}
            placeholder="75 characters or less."
            onChange={handleTraderService}
            maxLength={75}
            className="form-control"
            rows={2}
          />
          <br/>
Are you offering a product or a service? <br/><small>(you may select both)</small><br/>
<div style={{display:'flex', justifyContent:'center', marginTop:10}}>
  <div style={{margin:'0 9px'}}  >
Product: <input onChange={inputProduct} type="checkbox" value="product"/>
</div>
<div style={{margin:'0 9px'}}>
Service: <input onChange={inputService} type="checkbox" value="service"/>
</div>
</div>
          <br />
          <select className="form-control" onChange={handleCategory}>
            <option value="">Please select a category</option>
            {props.categories.sort().map((product) => (
              <option value={product}>{product}</option>
            ))}
          </select>
          <div  style={{marginBottom:10, textAlign:'center'}} >
          <small>If you feel that your category is missing, please contact me.</small>
          </div>
          
          <br />
          Can you provide your business/service online?
          <input style={{marginLeft:10}} type="checkbox" value="online" onChange={handleOnline} />
          <hr />
          A bit about yourself (your connection with the music world):
          <textarea
            value={traderStory}
            placeholder="400 characters or less."
            onChange={handleTraderStory}
            rows={15}
            className="form-control"
            maxLength={400}
          />
          <hr/>
          <h5>Tell us how to find you</h5>
          <small >choose how much you want to add here. </small>
          <hr/>
          Nearest town or city:{" "}
          <input
          
            value={traderCity}
            onChange={handleTraderCity}
            type="text"
            className="form-control form-input"
          />
          Country:{" "}
          <input
            value={traderCountry}
            onChange={handleTraderCountry}
            type="text"
            className="form-control form-input"
          />
          Email:{" "}
          <input
            value={traderEmail}
            onChange={handleTraderEmail}
            type="email"
            className="form-control form-input"
          />
          Website:{" "}
          <input
            value={traderWebsite}
            onChange={handleTraderWebsite}
            type="text"
            className="form-control form-input"
            placeholder="www..."
          />
          Telephone: {" "}
          <input
            value={traderTel}
            onChange={handleTraderTel}
            type="text"
            className="form-control"
            placeholder="starting with country code..."
          />
          <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:20}} >
          <button
            type="button"
            onClick={registerTrader}
            className="btn btn-success"
          >
            Publish
          </button>
          <button type="button" className="btn btn-light" onClick={props.close}>close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessRegistrationPage;
