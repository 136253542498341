import React from 'react'
import {Modal} from 'react-bootstrap'
import adam from '../assets/adam.jpg'
import { FaEnvelope } from 'react-icons/fa'

const AboutMe = (props) => {
    return (
        <Modal show={props.show}>
<div className="text-center" >
    <img src={adam} style={{width:'75%'}} alt="" />
    <h5 style={{marginTop:20}}>Adam Woolf</h5>
    <div style={{padding:'0 30px', textAlign:'justify'}}>
<p>I have been involved in developing websites and graphic design in one way or another since 2000.  
    This was an interest and skill which developed over time and became useful for promoting for the various 
    music groups of which I was a member as part of my busy career as a professional freelance musician. 
    </p>  
    <p>When COVID struck in 2020, with the cancellation of performing projects on a global scale, and the difficulties introduced by new travel restrictions, 
    I decided to turn what had already become an active sideline into my main focus. I currently freelance, running my
    own business, <a href="https://webspinner.eu" >WebSpinner.eu,</a> , with which I work regularly with a board, international client list comprising individuals and organisations from all walks of life.</p>
<p>This platform was developed purely to help musicians, performing artists, and others connected to the performing arts to promote their non-playing businesses, and to find and support each other. </p>

<p>If you have any feedback or suggestions regarding this platform, or any other enquiries regarding website design and development, I'd be really pleased to hear from you. </p>
</div>
    <a href="mailto:adam@webspinner.eu" style={{marginBottom:20}}  className="btn btn-primary btn-sm" ><FaEnvelope/> contact me</a>
    <button style={{marginBottom:20}} onClick={props.close} className="btn btn-light btn-sm" >close</button>
</div>

        </Modal>
    )
}

export default AboutMe
