import React, { useContext, useState } from "react";
import { MainContext } from "../Context";
import { Row, Col } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import BusinessRegistrationPage from "./BusinessRegistrationPage";

const BusinessManagementPage = (props) => {
  const {
    traderNameEdited,
    setTraderNameEdited,
    traderStoryEdited,
    setTraderStoryEdited,
    traderServiceEdited,
    setTraderServiceEdited,
    traderTelEdited,
    setTraderTelEdited,
    traderCityEdited,
    setTraderCityEdited,
    setTradingOnlineEdited,
    tradingOnlineEdited,
    traderWebsiteEdited,
    setTraderWebsiteEdited,
    traderCountryEdited,
    setTraderCountryEdited,
    traderEmailEdited,
    setTraderEmailEdited,
    setCategoryEdited,
    userBusiness,
    updateTrader,
    handleTraderImageFile,
    editTrader,
    deleteTrader,
    setManagementModal,
    registerMode,
    setRegisterMode,
    traderTarget,
    setTraderTarget,
    setIsProduct,
    setIsService,
    userBusinessesDisplayed,
  } = useContext(MainContext);

  const [editMode, setEditMode] = useState(false);
  const handleNameInput = (e) => setTraderNameEdited(e.target.value);
  const handleTraderEmail = (e) => setTraderEmailEdited(e.target.value);
  const handleTraderService = (e) => setTraderServiceEdited(e.target.value);
  const handleTraderCity = (e) => setTraderCityEdited(e.target.value);
  const handleTraderCountry = (e) => setTraderCountryEdited(e.target.value);
  const handleTraderWebsite = (e) => setTraderWebsiteEdited(e.target.value);
  const handleTraderTel = (e) => setTraderTelEdited(e.target.value);
  const handleTraderStory = (e) => setTraderStoryEdited(e.target.value);
  const handleOnline = (e) => setTradingOnlineEdited(true);
  const handleCategory = (e) => setCategoryEdited(e.target.value);
  const inputProduct = (e) => setIsProduct(true);
  const inputService = (e) => setIsService(true);

  const prepareDelete = (b) => {
    setTraderTarget(b.k);
  };
  const categories = [
    "Gifts",
    "Clothes",
    "Artwork",
    "Jewellery",
    "Food & Drink",
    "Tuition (Music)",
    "Tuition (Languages)",
    "Tuition (Other)",
    "Building & home improvement",
    "Administration / office work",
    "Accountancy",
    "Health & Fitness",
    "Photography",
    "Media creation (video / audio editing)",
    "Websites",
    "Translations",
    "Travel",
    "Other",
    "Garden & Outdoor items",
    "Handmade",
    "Budgeting & Finance"

  ];

  return (
    <div>
      <div className="registration-modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>My Business{userBusiness.length > 1 && <span>es</span>}</h4>
          <div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setManagementModal(false)}
            >
              close
            </span>
          </div>
        </div>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setRegisterMode(true)}
        >
          <span>
            <FaPlusCircle />{" "}
          </span>
          Add a business
        </button>

        {registerMode && (
          <BusinessRegistrationPage
            categories={categories}
            close={() => setRegisterMode(false)}
          />
        )}
        {!editMode &&
          userBusinessesDisplayed.map((b) => {
            return (
              <div>
                <hr />
                {b.brand && <h4>{b.brand}</h4>}
                {!b.brand && <h4>{b.name}</h4>}
                <Row>
                  <Col>
                    <p>{b.service}</p>
                    <p>Category: {b.category}</p>
                    <p>{b.city}, {b.country}</p>
                    <p>{b.email}</p>
                    <p>{b.tel}</p>
                    <p>{b.website}</p>
                    <p>{b.story}</p>
                  </Col>
                  <Col>
                    <img src={b.logo} alt="" style={{ width: "100%" }} />
                  </Col>
                </Row>

                <button
                  type="button"
                  className="btn btn-warning btn-sm"
                  onClick={() => {
                    setEditMode(true);
                    editTrader(b);
                  }}
                >
                  EDIT
                </button>

                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => prepareDelete(b)}
                >
                  DELETE
                </button>
                <div
                  className={
                    traderTarget === b.k ? "delete-panel open" : "delete-panel"
                  }
                >
                  <hr />
                  <p style={{ color: "red" }}>
                    Are you sure you want to proceed? This cannot be undone!
                  </p>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteTrader(b)}
                  >
                    DELETE
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-sm"
                    onClick={() => setTraderTarget("")}
                  >
                    cancel
                  </button>
                  <hr />
                </div>
                <br />
               
                <hr />
                <br />
                <br />
              </div>
            );
          })}

        {editMode && (
          <div>
            {" "}
            <h5>Tell us about your business or service:</h5>
            <form className="form-group" action="">
              Your business name or brand:{" "}
              <input
                value={traderNameEdited}
                placeholder="leave blank if this is the same as your chosen username"
                onChange={handleNameInput}
                className="form-control"
                type="text"
              />
              <hr />
              Add a logo <input onChange={handleTraderImageFile} type="file" />
              <br />
              <hr />
              What do you offer:{" "}
              <textarea
                value={traderServiceEdited}
                placeholder="75 characters or less."
                onChange={handleTraderService}
                maxLength={75}
                className="form-control"
                rows={2}
              />
              <br />
              Are you offering a product or a service? <br />
              <small>(you may select both)</small>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ margin: "0 9px" }}>
                  Product:{" "}
                  <input
                    onChange={inputProduct}
                    type="checkbox"
                    value="product"
                  />
                </div>
                <div style={{ margin: "0 9px" }}>
                  Service:{" "}
                  <input
                    onChange={inputService}
                    type="checkbox"
                    value="service"
                  />
                </div>
              </div>
              <br />
              <select className="form-control" onChange={handleCategory}>
                <option value="">Please select a category</option>
                {categories.sort().map((product) => (
                  <option key={product} value={product}>
                    {product}
                  </option>
                ))}
              </select>
              <br />
              Can you provide your business/service online?
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                value={tradingOnlineEdited}
                onChange={handleOnline}
              />
              <hr />
              A bit about yourself (your connection with the music world)
              <textarea
                value={traderStoryEdited}
                placeholder="400 characters or less."
                onChange={handleTraderStory}
                rows={15}
                className="form-control"
                maxLength={400}
              />
              <hr />
              <h5>Tell us how to find you</h5>
              Nearest town or city:{" "}
              <input
                value={traderCityEdited}
                onChange={handleTraderCity}
                type="text"
                className="form-control form-input"
              />
              Country:{" "}
              <input
                value={traderCountryEdited}
                onChange={handleTraderCountry}
                type="text"
                className="form-control form-input"
              />
              Email:{" "}
              <input
                value={traderEmailEdited}
                onChange={handleTraderEmail}
                type="email"
                className="form-control form-input"
              />
              Website:{" "}
              <input
                value={traderWebsiteEdited}
                onChange={handleTraderWebsite}
                type="text"
                className="form-control form-input"
                placeholder="you can copy this from your browser, starting with http..."
              />
              Telephone:{" "}
              <input
                value={traderTelEdited}
                onChange={handleTraderTel}
                type="text"
                className="form-control"
                placeholder="starting with country code..."
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    updateTrader();
                    setEditMode(false);
                  }}
                  className="btn btn-success"
                >
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={props.close}
                >
                  close
                </button>   </div>
            </form>
            <br/>
            <div className="text-center" >
                <small>clicking Update will take you back to the home page where you can search for your listing to see updates.</small>
                </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

export default BusinessManagementPage;
