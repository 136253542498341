import React, { useEffect, useState } from "react";
import ListItem from "./ListItem";
import { FaGlobe, FaEnvelope } from "react-icons/fa";

const ListView = ({
  traders,
  likes,
  setTradersDisplayed,
  tradersDisplayed,
  uniqueCats,
}) => {
  const [targetItem, setTargetItem] = useState("");

  useEffect(() => {
    setTradersDisplayed(traders);
  }, []);

  return (
    <div className="list-view">
      {uniqueCats.sort().map((cat) => {
        const filteredbyCat = tradersDisplayed.filter(
          (t) => t.category === cat
        );
        return (
          <div style={{ marginTop: 75 }}>
            <h5>{cat}</h5>
            {filteredbyCat.map((t, i) => (
              <>
                {" "}
                <div>
                  <ListItem
                    setTargetItem={setTargetItem}
                    index={i}
                    trader={t}
                  />
                </div>
                <div
                  className={
                    t.k === targetItem
                      ? "list-item-info open"
                      : "list-item-info"
                  }
                >
                  {t.logo && (
                    <>
                      {" "}
                      <span>by {t.name}</span> <br />{" "}
                    </>
                  )}
                  <span>{t.service}</span>
                  <br />
                  <hr />
                  <span>
                    Location: {t.city} {t.country}
                  </span>
                  <br />

                  <a style={{ marginLeft: 15 }} href={`mailto:${t.email}`}>
                    <FaEnvelope /> {t.email}
                  </a>
                  <a
                    style={{ marginLeft: 15 }}
                    href={t.website}
                    target="_blank"
                  >
                    <FaGlobe /> visit website
                  </a>
                </div>
              </>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default ListView;
