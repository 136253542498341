import React, { useContext} from 'react'
import {MainContext} from './Context'
import { Route, Switch } from 'react-router-dom'
import './styles/style.css';
import Toolbar from './components/toolbar/Toolbar'
import BusinessRegistrationPage from './components/BusinessRegistrationPage'
import Footer from './components/Footer'
import Directory from './components/directory/Directory'
import {Modal} from 'react-bootstrap'
import DonateButton from './components/DonateButton';
import SharingButtons from './components/SharingButtons';
import Header from './components/Header';
import BusinessManagementPage from './components/BusinessManagementPage';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import Particles from 'react-tsparticles';
import ManageAccount from './auth/ManageAccount';
import Admin from './components/Admin';



function App() {
  const {user, editUserName, setEditUserName, manageAccount, setManageAccount, userNameEdited, submitNewUserName, handleNewUserName, deleteMode, setDeleteMode, errorMessage, setErrorMessage, userBusiness, deleteCurrentUser, registrationModal, setRegistrationModal, managementModal, setManagementModal} = useContext(MainContext)
  

return <div>
  <div className="App">

    <Header/>
    {/* <Switch>
      <Route path="/admin" component={Admin}  />
    </Switch> */}
<Toolbar/>
<div className="particles"  >
                <Particles
            id="tsparticles"
            options={{
              background: {
                color: {
                  value: "#20A4A4",
                },
              },
              fpsLimit: 60,
              interactivity: {
                detectsOn: "canvas",
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 100,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5,
                },
              },
              detectRetina: true,
            }}
          />    
          </div>

<Modal show={managementModal} >
<BusinessManagementPage close={() => setManagementModal(false)} />
</Modal>

<Modal show={registrationModal}>
<BusinessRegistrationPage close={() => setRegistrationModal(false)} />
</Modal>

<Directory/>
<SharingButtons/>
<DonateButton/>
{ user && <ManageAccount 
setManageAccount={setManageAccount} 
manageAccount={manageAccount}
setEditUserName={setEditUserName}
editUserName={editUserName}
userNameEdited={userNameEdited}
handleNewUserName={handleNewUserName}
submitNewUserName={submitNewUserName}
setDeleteMode={setDeleteMode}
deleteMode={deleteMode}
userBusiness={userBusiness}
deleteCurrentUser={deleteCurrentUser}
errorMessage={errorMessage}
setErrorMessage={setErrorMessage}


/>
}
<Footer/>
  </div>
  </div>
}
 


export default App;
