import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import { Favorite } from '@material-ui/icons';

const MyButton = withStyles({
    root:{
      margin:4,
      borderRadius:25,
      textTransform:'capitalize',
      color: 'white',
      backgroundColor: 'rgb(32, 164, 164)',
      outlineColor:'none',
      outline: 'none',
      border:'none',
    '&:hover': {
        backgroundColor: 'rgb(23, 118, 118)',
        // borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        outline:'none'
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(23,118,118,.5)',
        outline:'none'
      },
      
  }})(Button)

const CustomButton = ({text, click, icon}) => <MyButton
onClick={click}
endIcon={icon}

 variant="outlined" >{text}</MyButton>
  
 
export default CustomButton
