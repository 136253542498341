import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { MainContext } from "../../Context";
import "./Toolbar.css";
import SignInForm from "../../auth/SignInForm";
import SignUpForm from "../../auth/SignUpForm";
import { FaEdit } from "react-icons/fa";
import firebase from "firebase";
import CustomButton from "../CustomButton";
import { Business } from '@material-ui/icons'

const Toolbar = () => {
  const {
    user,
    avatar,
    getUserBusiness,
    loginModal,
    setLoginModal,
    createAccountModal,
    setCreateAccountModal,
  } = useContext(MainContext);

  useEffect(() => {
    authListener();
  }, []);

  const authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
      } else {
      }
    });
  };

  return (
    <div>
      <div className="toolbar">
        {user && (
          <div style={{ width: "50%" }}>
            <CustomButton icon={<Business/>} text="My business" click={getUserBusiness} />
            {/* <button onClick={getUserBusiness} className="btn btn-primary btn-sm"><FaEdit/> My Business</button> */}
          </div>
        )}
        {user && (
          <div className="user-profile text-right">
            <span>Welcome, {user.displayName}</span>
            <div className="avatar">
              {" "}
              <img src={avatar} alt="" />
            </div>
          </div>
        )}

        {!user && (
          <CustomButton text="login" click={() => setLoginModal(true)} />

          // <button
          //   onClick={() => setLoginModal(true)}
          //   className="btn btn-primary btn-sm"
          //   type="button"
          // >
          //   login
          // </button>
        )}
        {!user && (
          <div>
            <CustomButton
              text="Create an account"
              click={() => setCreateAccountModal(true)}
            />

            {/* <button
              onClick={() => setCreateAccountModal(true)}
              className="btn btn-success btn-sm"
            >
              create an account
            </button> */}
            <Modal
              show={createAccountModal}
              close={() => setCreateAccountModal(false)}
            >
              <SignUpForm close={() => setCreateAccountModal(false)} />
            </Modal>
          </div>
        )}
      </div>
      <Modal show={loginModal}>
        <SignInForm close={() => setLoginModal(false)} />
      </Modal>
    </div>
  );
};

export default Toolbar;
