import React from 'react'
import Particles from 'react-tsparticles'




const ParticlesComp = () => {
    return (
        <div style={{position:'relative', overflow:'hidden'}} >
       <div className="main-logo" >
  <div className="main-logo-text" >
  <h2>ALWAYS</h2>
  <div className="main-logo-text-box" >
  <h1>ON</h1>
    {/* <h6 style={{marginTop:-30}} >{traders.length} listings</h6> */}
    </div>
    {/* <button onClick={all} className="btn btn-light" >View all listings</button> */}
  <p>www.ossianet.com</p>
  </div>
</div>
          <div style={{postion:'relative'}} >
 
          <Particles
            id="tsparticles"
            options={{
              background: {
                color: {
                  value: "#20A4A4",
                },
              },
              fpsLimit: 60,
              interactivity: {
                detectsOn: "canvas",
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 100,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5,
                },
              },
              detectRetina: true,
            }}
          />    
          </div>
          

           
        </div>
    )
}

export default ParticlesComp;
