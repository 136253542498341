import React, {useState, useEffect} from 'react'
import Particles from 'react-tsparticles'
import logo from '../assets/chameleonAlone.png'

const Header = () => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
},[])

    return (
        <div style={{transform: `translateY(${offsetY * .5}px)`, zIndex:-1}} >
           
       
<div className="toolbar-header" >
    
<h1>OssiaNet</h1>

<p>THE searchable online directory of alternative enterprises & skills offered by & performing artists worldwide.  </p>


</div>
        </div>
    )
}

export default Header
