import React from "react";
import logo from "../../assets/ON.jpg";

import {FaEnvelope, FaGlobe, FaPlusCircle } from 'react-icons/fa'

const listTitle = {
  fontWeight:'bold',
  width:290
  // fontSize:'1rem'
}

const ListItem = ({ trader, index, setTargetItem }) => {


  return (<>
    <div key={index} className="list-item" onClick={() => setTargetItem(trader.k)}>
      <div className="list-icon">
        {trader.logo && <img src={trader.logo} />}
        {!trader.logo && <img src={logo} />}
      </div>
      {trader.brand && <span style={listTitle} >{trader.brand}</span>}
      {!trader.brand && <span style={listTitle} >{trader.name}</span>}
      <span>
      <a href={`mailto:${trader.email}`}><FaEnvelope/></a>
      </span>
      <div style={{padding:5}} >
     <FaPlusCircle  />
     </div>

    </div>
   
    </>
  );
};

export default ListItem;
