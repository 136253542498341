import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'

const ManageAccount = ({
    setManageAccount, manageAccount, setEditUserName,
    editUserName, userNameEdited, handleNewUserName, submitNewUserName,
    setDeleteMode, deleteMode, userBusiness, deleteCurrentUser,
    errorMessage, setErrorMessage
}) => {
    return (
        <div style={{padding:20}}>
         <small style={{color:'goldenrod', cursor:'pointer'}} onClick={() => setManageAccount(true)} >Manage my account</small>
         <div className={manageAccount ? "account-management-window open" : "account-management-window"} > 
           <div>
            <small onClick={() => setEditUserName(true)} style={{color:'blue', cursor:'pointer'}}>Edit username<br/></small>
                <div className={editUserName ? 'edit-username open' : 'edit-username'} >
                        <div style={{display:'flex'}} >        
                            <input placeholder="Please enter your new username
                            " value={userNameEdited} onChange={handleNewUserName} className="form-control" style={{width:350}} type="text"/>
                            <button type="button" className="btn btn-primary btn-sm" onClick={submitNewUserName}>submit</button>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => setEditUserName(false)}>cancel</button>
                        </div>
                </div>
                
           
            <small onClick={() => setDeleteMode(true)} style={{color:'red', cursor:'pointer'}}>Delete my account<br/>
            <small style={{color:'black'}} >this requires first deleting all of your businesses from the system.</small></small>
            </div>
            <div className={deleteMode ? 'delete-panel open' : 'delete-panel'} >
              <p>Are you sure you want to delete your account? </p>
              {userBusiness.length > 0 && <p style={{color:'red'}} >You cannot proceed until you have deleted all of your businesses.
              <br/>Please do so using the Business Management button on the toolbar.</p>}
            <button type="button" disabled={userBusiness.length > 0} className="btn btn-danger btn-sm" onClick={deleteCurrentUser}>DELETE</button>
            <button className="btn btn-light btn-sm" onClick={() => setDeleteMode(false)}>cancel</button>
            {errorMessage && (
              <div className="error-message">
                <p>
                  {errorMessage}
                  <br />
                  <small
                    style={{ cursor: "pointer" }}
                    onClick={() => setErrorMessage(null)}
                  >
                    close
                  </small>
                </p>
              </div>)}

              </div>
              <small style={{cursor:'pointer'}} onClick={() => setManageAccount(false)} > close <FaTimesCircle/></small>
              </div>
            </div>
    )
}

export default ManageAccount
