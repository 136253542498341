import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../Context";
import BusinessCard from "./BusinessCard";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import { Row, Col } from "react-bootstrap";
import ParticlesComp from "../Particles";
import Spinner from "../spinner/spinner";
import Tiles from "../Tiles";
import ListView from "./ListView";

import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";


import { Favorite, Dashboard, List, StarOutline } from "@material-ui/icons";
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from "../customSelect/CustomSelect";




const Directory = () => {

  const {
    user,
    traders,
    loading,
    updateLikesinFB,
    setCreateAccountModal,
    setLoginModal,
    fetchTraders,
    favIds,
  } = useContext(MainContext);
  useEffect(() => {
    fetchTraders();
  }, []);
  const [tradersDisplayed, setTradersDisplayed] = useState([]);
  const [listView, setListView] = useState(false);

  let categories;
  if (tradersDisplayed.length === 0)
    categories = traders.map((t) => t.category);
  if (tradersDisplayed.length > 0)
    categories = tradersDisplayed.map((t) => t.category);

  const uniqueCats = [...new Set(categories)];

  let cities;
  if (tradersDisplayed.length === 0) {
    cities = traders.map((t) => t.city);
  }
  if (tradersDisplayed.length > 0) {
    cities = tradersDisplayed.map((t) => t.city);
  }
  const uniqueCities = [...new Set(cities)];

  const [term, setTerm] = useState("");
  const [displayTerm, setDisplayTerm] = useState("");

  // LIKES
  const handleLikeIncrement = (t, i) => {
    const array = [...tradersDisplayed];
    array[i].likes++;
    setTradersDisplayed(array);
    const selected = array.filter((trader) => trader.k === t.k);
    updateLikesinFB(selected[0]);
  };

  const recents = () => {
    const array = [...traders];
    const filtered = array.slice(array.length - 4, array.length);

    console.log(filtered);
    setTradersDisplayed(filtered);
    console.log(filtered);
  };

  const filterByCat = (e) => {
    const cat = e.target.value;
    setDisplayTerm(e.target.value);
    if (cat === "all") {
      setTradersDisplayed([]);
    } else {
      const array = [...traders];
      const filtered = array.filter((t) => t.category === cat);
      setTradersDisplayed(filtered);
    }
  };

  const filterByTown = (e) => {
    const cat = e.target.value;
    setDisplayTerm(e.target.value);
    if (cat === "all") {
      setTradersDisplayed([]);
    } else {
      const array = [...traders];
      const filtered = array.filter((t) => t.city === cat);
      setTradersDisplayed(filtered);
    }
  };

  const handleInputSearch = (e) => {
    setTerm(e.target.value.toLowerCase());
    setDisplayTerm(e.target.value);
    if (term.length > 1) {
      searchTraders();
    }
    if (e.target.value === "") {
      setTradersDisplayed([]);
    }
  };

  const searchTraders = () => {
    const array = [...traders];
    const filtered = array.filter(
      (t) =>
        t.service.toLowerCase().includes(term) ||
        t.brand.toLowerCase().includes(term) ||
        t.name.toLowerCase().includes(term) ||
        t.city.toLowerCase().includes(term) ||
        t.story.toLowerCase().includes(term) ||
        t.country.toLowerCase().includes(term)
    );
    setTradersDisplayed(filtered);
  };

  const services = traders.filter((x) => x.isService);
  const products = traders.filter((x) => x.isProduct);
  const online = traders.filter((x) => x.online);

  const viewProducts = () => {
    setDisplayTerm("");
    setTradersDisplayed(products);
  };

  const viewServices = () => {
    setDisplayTerm("");
    setTradersDisplayed(services);
  };

  const luckyDip = () => {
    setDisplayTerm("");
    var item = traders[Math.floor(Math.random() * traders.length)];
    console.log(item);

    setTradersDisplayed([item]);
  };

  const shuffle = () => {
    const array = [...traders];
    const chosen = array.slice(2, 1);
    setTradersDisplayed(chosen);
  };

  const filter = (cat) => {
    const array = [...traders];
    const filtered = array.filter((t) => t.category === cat);
    setTradersDisplayed(filtered);
  };

  const viewAll = () => {
    setDisplayTerm("");
    setTradersDisplayed(traders);
  };

  const viewOnline = () => {
    setDisplayTerm("");
    const array = [...traders];
    const filtered = array.filter((t) => t.online);
    setTradersDisplayed(filtered);
  };

  const viewFavs = () => {
    setDisplayTerm("");
    const selected = traders.filter((x) => favIds.includes(x.k));
    setTradersDisplayed(selected);
  };

  return (
    <>
      <div className="directory">
        <div>
          {!user && (
            <p>
              Search the directory to find people offering services and products
              online or near you.
              <br /> If you would like to leave a review, create a list of
              favourites, or advertise your own business, please{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setLoginModal(true)}
              >
                {" "}
                sign in
              </span>
              , or{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setCreateAccountModal(true)}
              >
                {" "}
                create a free account{" "}
              </span>{" "}
              using the green button above.
            </p>
          )}
          {user && (
            <p style={{ paddingTop: 25 }}>
              Thanks for logging in, {user.displayName}. <br /> Search the
              directory to find people offering services and products online or
              near you. <br /> You can also click the heart to add cards to your
              Favourites list, write reviews and of course, use the share button
              to send details of your favourite listings to your friends' email.
            </p>
          )}
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="search-panel">
              <Row>
                <Col md={4} className="text-center">
                  <div style={{ marginBottom: 10 }}>
                    <CustomButton
                      click={viewAll}
                      text={`View all (${traders.length})`}
                    />

                    <CustomButton
                      click={viewServices}
                      text={`Services (${services.length})`}
                    />
                    <CustomButton
                      click={viewProducts}
                      text={`Products (${products.length})`}
                    />

                    {user && (
                      <CustomButton
                        click={viewFavs}
                        text={`Favourites`}
                        icon={<Favorite color="secondary" />}
                      />
                    )}

                    <CustomButton click={viewOnline} text={`Online Services (${online.length})`} />
                    <CustomButton click={luckyDip} text="Lucky Dip!" />
                    <CustomButton
                      click={recents}
                      text="New"
                      icon={<StarOutline style={{ color: "yellow" }} />}
                    />
                  </div>
                </Col>
                <Col md={4} className="search-box">
                  <CustomInput handleInputSearch={handleInputSearch}/>
                </Col>
                <Col md={4} className="text-center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CustomSelect 
                    label="Select a Category"
                    traders={traders}
                    options={uniqueCats} 
                    value={displayTerm} 
                    onchange={filterByCat} />

                    <CustomSelect 
                    label="Select a Town"
                    traders={traders}
                    options={uniqueCities} 
                    value={displayTerm} 
                    onchange={filterByTown} />
                  </div>
                </Col>
              </Row>
              <CustomButton
                icon={<Dashboard />}
                click={() => setListView(false)}
                text="Cards & Tiles View"
              />
              <CustomButton
                icon={<List />}
                click={() => setListView(true)}
                text="List View"
              />
            </div>
          </>
        )}
      </div>

      {loading && <ParticlesComp />}
      {!listView && tradersDisplayed.length === 0 ? (
        <Tiles
          luckyDip={luckyDip}
          recent={recents}
          filter={filter}
          traders={traders}
        />
      ) : (
        <div>
          <div>
            {tradersDisplayed.length !== 0 && (
              <div className="results-summary">
                {" "}
                <h5>
                  Showing {tradersDisplayed.length} enterprise
                  {tradersDisplayed.length > 1 && <span>s</span>}
                  {displayTerm !== "all" && displayTerm !== "" && (
                    <span> containing {displayTerm}</span>
                  )}
                </h5>
                <button
                  className="btn btn-light btn-sm"
                  onClick={() => {
                   if(listView){setTradersDisplayed(traders)}
                   else{setTradersDisplayed([])}
                  
                  }}
                >
                  clear filter
                </button>{" "}
              </div>
            )}
            {!listView && (
              <div className="cards-background">
                {tradersDisplayed
                  .sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  })
                  .map((item, index) => (
                    <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                      <BusinessCard
                        index={index}
                        key={item.key}
                        trader={item}
                        likes={handleLikeIncrement}
                      />
                    </ScrollAnimation>
                  ))}
              </div>
            )}
            {listView && (
              <ListView
                setTradersDisplayed={setTradersDisplayed}
                likes={handleLikeIncrement}
                traders={traders}
                tradersDisplayed={tradersDisplayed}
                uniqueCats={uniqueCats}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Directory;
