import React from 'react'
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "rgb(32, 164, 164)",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "rgb(32, 164, 164)",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgb(32, 164, 164)",
        },
        "&:hover fieldset": {
          borderColor: "rgb(32, 164, 164)",
          borderWidth:2
        },
        "&.Mui-focused fieldset": {
          borderColor: "rgb(32, 164, 164)",
        },
      },
    },
  })(TextField);

const CustomInput = ({handleInputSearch}) => {
    return (
        <CssTextField
                    className="search-input"
                    onChange={handleInputSearch}
                    id="input-with-icon-grid"
                    label="Type to search..."
                    variant="outlined"
                  />
    )
}

export default CustomInput
