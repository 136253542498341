import React, {useState, useEffect, createContext} from 'react'
import firebase from './firebase'
import moment from 'moment'

const MainContext = createContext(null)

const ContextProvider = props => {

const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [pic, setPic] = useState(null)
const [avatar, setAvatar] = useState('')
const [user, setUser] = useState({})
const [traders, setTraders] = useState([])
const [currentUserKey, setCurrentUserKey] = useState('')


useEffect(() => {
    setLoading(true)
    console.log(moment().format())
    firebase.auth().onAuthStateChanged(user => {
        
        setUser(user)
        if(user){

            firebase.storage().ref(`users/${user.uid}/profile.jpg`).getDownloadURL()
            .then(imgUrl => { 
                setAvatar(imgUrl)
                firebase.auth().currentUser.updateProfile({photoURL: imgUrl})                
            })


            firebase.database().ref('users').on('value', snapshot => {
                const users = snapshot.val()              
                let keys = []
                if(users){keys = Object.keys(users)}
            
                let array = []
            
                for(let i = 0; i < keys.length; i++ ) {
                    let k = keys[i];
                    let name = users[k].name;
                    let password = users[k].password;
                    let email = users[k].email      
                    array.push({ k, name, password, email})
                }
                setUsers(array)
            
                // GET CURRENT USER FIREBASE KEY
                const currentUser = array.filter(x => x.email === user.email)
                setCurrentUserKey(currentUser[0].k)
           

            firebase.database().ref(`users/${currentUser[0].k}/favourites`)
            .on('value', (snapshot) => {
            
            const posts = snapshot.val()
             let keys = []
               if(posts) { keys = Object.keys(posts)}
            //    console.log(keys);

                console.log(posts)
                let array = []
            
                for(let i = 0; i < keys.length; i++){
                    let k = keys[i];
                    let id = posts[k].id;
                //    console.log(k, id);
                    array.push( {key: k, id,} )
                    // console.log( array);
                    setFavTraders(array)
                    setFavIds(array.map(x => x.id))
                } })
            })
        }
    });
}, [])


// BUSINESS REGISTRATION CODE
const [registerMode, setRegisterMode] = useState(false)

const [traderName, setTraderName] = useState('')
const [traderEmail, setTraderEmail] = useState('')
const [traderTel, setTraderTel] = useState('')
const [traderCity, setTraderCity] = useState('')
const [traderCountry, setTraderCountry] = useState('')
const [traderImage, setTraderImage] = useState('')
const [traderStory, setTraderStory] = useState('')
const [traderService, setTraderService] = useState('')
const [traderWebsite, setTraderWebsite] = useState('')
const [tradingOnline, setTradingOnline] = useState(false)
const [category, setCategory] = useState('')
const [likes, setLikes] = useState(0)
const [registrationModal, setRegistrationModal] = useState(false)
const [isProduct, setIsProduct] = useState(false)
const [isService, setIsService] = useState(false)


const handleTraderImageFile = async e => {
    const file = e.target.files[0]

    var filesize = ((file.size/1024)/1024).toFixed(2); // MB
    if(filesize > .3){
        alert(`please choose a smaller file (max. 300kb).  Current file size is ${filesize} Mb.`)
    return
}

    const storageRef = firebase.storage().ref(`users/${user.uid}/logos`)
    const fileRef = storageRef.child(file.name)
    await  fileRef.put(file)
    setTraderImage(await fileRef.getDownloadURL())
}

const registerTrader = async () => {
    let avatar = "";
    if(user.photoURL) {avatar = user.photoURL}
const newTrader = {id: user.uid, brand: traderName, name: user.displayName, avatar,
   logo: traderImage, email: traderEmail, tel: traderTel, city: traderCity, country: traderCountry, story: traderStory, service: traderService, 
   website: traderWebsite, online: tradingOnline, category: category, likes: likes, isProduct, isService, submitted: moment().format(),
}
await firebase.database().ref('traders').push(newTrader)
// getUserBusiness()
setUserBusinessesDisplayed([...userBusiness, newTrader])

setRegistrationModal(false)
setRegisterMode(false)
setTraderName('')
setTraderEmail('')
setTraderTel('')
setTraderCity('')
setTraderCountry('')
setTraderImage('')
setTraderStory('')
setTraderService('')
setTraderWebsite('')
setCategory('')
setIsProduct(false)
setIsService(false)

}

const [loading, setLoading] = useState(true)


const fetchTraders = () => {
    setLoading(true)
firebase.database().ref('traders').on('value', snapshot => {
    const traders = snapshot.val()
  
    let keys = []
    if(traders){keys = Object.keys(traders)}

    let array = []

    for(let i = 0; i < keys.length; i++ ) {
        let k = keys[i];
        let id = traders[k].id
        let name = traders[k].name;
        let brand = traders[k].brand
        let service = traders[k].service
        let story = traders[k].story;
        let email = traders[k].email;
        let website = traders[k].website;
        let tel = traders[k].tel;
        let city = traders[k].city;
        let country = traders[k].country;
        let online = traders[k].online;
        let category = traders[k].category
        let logo = traders[k].logo
        let likes = traders[k].likes
        let isService = traders[k].isService;
        let isProduct = traders[k].isProduct;
        // let avatar = traders[k].avatar;
        let submitted = traders[k].submitted;

        if(country === 'United Kingdom') country = 'UK'
        if(city === 'LONDON') city = 'London'
  
        array.push({id, k, name, brand, category, email, 
            // avatar, 
            isProduct, isService, website, tel, city, country, story,  service, logo, online, likes, submitted})
    }
    setTraders(array)
    setLoading(false)


})

}

// USER BUSINESS MANAGEMENT
const [userBusiness, setUserBusiness] = useState([])
const [userBusinessesDisplayed, setUserBusinessesDisplayed] = useState([])
const [managementModal, setManagementModal] = useState(false)

const getUserBusiness = () => {
    setManagementModal(true)
    // console.log(traders.filter(t => t.id === user.uid))
    setUserBusiness(traders.filter(t => t.id === user.uid))
    setUserBusinessesDisplayed(traders.filter(t => t.id === user.uid))
    
}

// EDIT BUSINESS
const [traderNameEdited, setTraderNameEdited] = useState('')
const [traderEmailEdited, setTraderEmailEdited] = useState('')
const [traderTelEdited, setTraderTelEdited] = useState('')
const [traderCityEdited, setTraderCityEdited] = useState('')
const [traderCountryEdited, setTraderCountryEdited] = useState('')
const [traderImageEdited, setTraderImageEdited] = useState(null)
const [traderStoryEdited, setTraderStoryEdited] = useState('')
const [traderServiceEdited, setTraderServiceEdited] = useState('')
const [traderWebsiteEdited, setTraderWebsiteEdited] = useState('')
const [tradingOnlineEdited, setTradingOnlineEdited] = useState(false)
const [categoryEdited, setCategoryEdited] = useState('')
const [editedTraderKey, setEditedTraderKey] = useState('')
const [editedTraderLikes, setEditedTraderLikes] = useState(0)
const [isProductEdited, setIsProductEdited] = useState(false)
const [isServiceEdited, setIsServiceEdited] = useState(false)

const editTrader = (t) => {
    setTraderServiceEdited(t.service)
    setTraderNameEdited(t.brand)
    setTraderEmailEdited(t.email)
    setCategoryEdited(t.category)
    setTradingOnlineEdited(t.online)
    setTraderStoryEdited(t.story)
    setTraderCityEdited(t.city)
    setTraderCountryEdited(t.country)
    setTraderWebsiteEdited(t.website)
    setTraderTelEdited(t.tel)
    setEditedTraderKey(t.k)
    setEditedTraderLikes(t.likes)
    setTraderImage(t.logo)
    setIsProductEdited(t.isProduct)
    setIsServiceEdited(t.isService)

    //  console.log(t);
    
}

const updateTrader = async () => {
const updatedTrader = {
    id: user.uid, 
    brand: traderNameEdited, 
    name: user.displayName, 
    logo: traderImage,
     email: traderEmailEdited, 
     tel: traderTelEdited, city: 
     traderCityEdited, 
     country: traderCountryEdited, 
     story: traderStoryEdited, 
     service: traderServiceEdited, 
    website: traderWebsiteEdited, 
    online: tradingOnlineEdited, 
    category: categoryEdited, 
    likes: editedTraderLikes, 
    isProduct: isProductEdited, 
    isService: isServiceEdited 
}
 
    
firebase.database().ref(`traders/${editedTraderKey}`).set(updatedTrader)
setManagementModal(false)
 setTraderNameEdited('')
 setTraderEmailEdited('')
 setTraderTelEdited('')
 setTraderCityEdited('')
 setTraderCountryEdited('')
 setTraderImageEdited(null)
 setTraderStoryEdited('')
 setTraderServiceEdited('')
 setTraderWebsiteEdited('')
 setTraderImage(null)
 setIsProductEdited(false)
 setIsServiceEdited(false)
 setTradingOnlineEdited(false)

}

// DELETE TRADER

const [traderTarget, setTraderTarget] = useState('')

const [deleteTraderMode, setDeleteTraderMode] = useState(false)

const deleteTrader =  (t) => {
    const array = [...userBusinessesDisplayed]
    const filtered = array.filter(x => x.k !== t.k)
    setUserBusinessesDisplayed(filtered)

 firebase.database().ref(`traders/${t.k}`).set({})
//  console.log('deleted')
 setTraderTarget('')

}




//FAVOURITES

const [favIds, setFavIds] = useState([])
const [favTraders, setFavTraders ] = useState([])



const getFavouriteTradersFromFirebase = () => {
    // console.log(user.uid);
    
}

const toggleFavs = (id) => {

    if(favIds.includes(id)){
        const array = [...favIds]
       const filtered = array.filter(x => x !== id)
      setFavIds(filtered)

       // DEAL WITH FB
       const workingArray = [...favTraders]
       const favToRemove = workingArray.filter(a => a.id === id)
    //    console.log(id);
       
     firebase.database().ref(`users/${currentUserKey}/favourites/${favToRemove[0].key}`).set({})

  
      
    } else {
        const newArray = [...favIds]
         newArray.push(id)
        setFavIds(newArray)

      firebase.database().ref(`users/${currentUserKey}/favourites`).push({id: id})
      
       }
};

// SHARE TRADER INFO

const share = (t) => {
    // console.log('sharing: ' + t.name + t.email + t.website);
    
}



const [errorMessage, setErrorMessage] = useState(null)
const [loginModal, setLoginModal] = useState(false);

const handleLogIn = (e) => {
    e.preventDefault()
   firebase.auth().signInWithEmailAndPassword(email, password)
   .then((user) => {
       setUser(user)
       setLoginModal(false)
})
   .catch((error) => {
      let errorMessage = error.message;
// console.log(errorMessage)
setErrorMessage(errorMessage)

    setEmail('')
    setPassword('')
   });
 }

const signout = () => {
    firebase.auth().signOut()
    setUser(null)
}

// USER REGISTRATION
const [createAccountModal, setCreateAccountModal] = useState(false);

const createUser = () => {
firebase.auth().createUserWithEmailAndPassword(email, password).then(auth => {
firebase.database().ref(`users`).push({name, email, password, id:auth.user.uid})
setCreateAccountModal(false)

return auth.user.updateProfile({displayName: name}),
  
firebase.storage().ref(`users/${auth.user.uid}/profile.jpg`).put(pic)
.then(() => console.log('upload successful') ).catch(error => console.log(error.message)
)
}).catch(error => {
    setErrorMessage(error.message)
    // console.log('from Auth: ' + error.message)
})
}

// DELETE USER
const [deleteMode, setDeleteMode] = useState(false)

const deleteCurrentUser = () => {
    firebase.auth().currentUser.delete().then().catch(error => setErrorMessage(error.message))
// console.log('user deleted');
setDeleteMode(false)

}

// EDIT USER NAME
const [editUserName, setEditUserName] = useState(false)
const [manageAccount, setManageAccount] = useState(false)
const [userNameEdited, setUserNameEdited] = useState('')
const handleNewUserName = e => setUserNameEdited(e.target.value)

const submitNewUserName = () => {
firebase.auth().currentUser.updateProfile({displayName: userNameEdited})
setUserNameEdited('')
setEditUserName(false)
window.location.reload()
   
}

// LIKES
const updateLikesinFB = (trader) => {
// console.log(trader);
firebase.database().ref(`traders/${trader.k}`).set(trader)

}

// GET KEYS FOR USERS ON FIREBASE

const [users, setUsers] = useState([])



    return (
        <MainContext.Provider
        value={{
            share,
            submitNewUserName,
            handleNewUserName,
            editUserName,
            setEditUserName,
            manageAccount,
            setManageAccount,
            userNameEdited,
            setUserNameEdited,
            loading,
            setLoading,
            createUser,
            setEmail,
            setName,
            setPassword,
            setPic,
            avatar,
            user,
            signout,
            handleLogIn,
            traderName,
            setTraderName,
            traderEmail,
            setTraderEmail,
            traderTel,
            setTraderTel,
            traderCity,
            setTraderCity,
            traderCountry,
            setTraderCountry,
            traderStory,
            setTraderStory,
            traderService,
            setTraderService,
            traderImage,
            setTraderImage,
            handleTraderImageFile,
            traderWebsite,
            setTraderWebsite,
            registerTrader,
            fetchTraders,
            traders,
            setTradingOnline,
            setCategory,
            registrationModal,
            setRegistrationModal,
            getUserBusiness,
            userBusiness,
            managementModal,
            setManagementModal,
            updateTrader,
            traderNameEdited,
            setTraderNameEdited,
            setCategoryEdited,
            categoryEdited,
            traderEmailEdited,
            setTraderEmailEdited,
            traderTelEdited,
            setTraderTelEdited,
            traderCityEdited,
            setTraderCityEdited,
            traderCountryEdited,
            setTraderCountryEdited,
            traderStoryEdited,
            setTraderStoryEdited,
            traderServiceEdited,
            setTraderServiceEdited,
            traderImageEdited,
            setTraderImageEdited,
            traderWebsiteEdited,
            setTraderWebsiteEdited,
            setTradingOnlineEdited,
            tradingOnlineEdited,
            editTrader,
            deleteTrader,
            registerMode,
            setRegisterMode,
            favTraders,
            favIds,
            toggleFavs,
            getFavouriteTradersFromFirebase,
            errorMessage,
            setErrorMessage,
            loginModal,
            setLoginModal,
            createAccountModal,
            setCreateAccountModal,
            deleteCurrentUser,
            deleteMode,
            setDeleteMode,
            deleteTraderMode,
            setDeleteTraderMode,
            setIsProduct,
            setIsService,
            setIsProductEdited,
            setIsServiceEdited,
            isProduct,
            isService,
            isProductEdited,
            isServiceEdited,
            traderTarget,
            setTraderTarget,
            userBusinessesDisplayed,
            setUserBusinessesDisplayed,
            updateLikesinFB,
            users
          
        }}
        >
            {props.children}
        </MainContext.Provider>
    )
}

const MainConsumer = MainContext.Consumer;
export {MainContext, MainConsumer}

export default ContextProvider