import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  FaThumbsUp,
  FaDesktop,
  FaEnvelope,
  FaHeart,
  FaPhoneAlt,
  FaShareAlt,
  FaTimesCircle,
} from "react-icons/fa";
import moment from "moment";
import sanitizeHtml from "sanitize-html";
import marked from "marked";
import firebase from "../../firebase";

const DetailsModal = ({
  handleLikeIncrement,
  index,
  toggleFavs,
  favIds,
  reviewsToPrint,
  reviews,
  user,
  show,
  close,
  t,
}) => {
  const [reviewMode, setReviewMode] = useState(false);

  const [reviewText, setReviewText] = useState("");

  const handleReviewInput = (e) => setReviewText(e.target.value);

  const submitReview = () => {
    const review = {
      by: user.displayName,
      email: user.email,
      submitted: moment().format(),
      content: reviewText,
      traderId: t.id,
    };
    console.log(review);
    console.log(reviews.length);
    firebase.database().ref(`traders/${t.k}/reviews`).push(review);
    setReviewMode(false);
    setReviewText("");
  };

  const [postDeleteMode, setPostDeleteMode] = useState(false);
  const deleteReview = (review, index) => {
    firebase.database().ref(`traders/${t.k}/reviews/${review.key}`).set({});
    reviewsToPrint.filter((x) => x.index !== review.index);
    console.log(index);
    console.log(reviews.length);
    setPostDeleteMode(false);
  };

  return (
    <Modal show={show}>
      <div>
        <div className="text-center">
          <img
            src={t.logo}
            alt=""
            style={{ width: "60%", margin: "auto", marginBottom: 10 }}
          />
          {/* {!t.logo && (
            <img
            alt=""
              src={t.avatar}
              style={{ width: "60%", margin: "auto", marginBottom: 10 }}
            />
          )} */}
        </div>

        <div className="text-center">
          {t.brand && (
            <div>
              <h4>{t.brand}</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>by {t.name}</span>
                {/* {t.avatar && (
                  <div className="avatar">
                    <img src={t.avatar} alt="" />
                  </div>
                )} */}
              </div>
              {/* {t.likes === 0 && (
                <FaThumbsUp
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLikeIncrement(t, index)}
                />
              )}
              {t.likes > 0 && (
                <span style={{ color: "green", cursor: "pointer" }}>
                  <FaThumbsUp onClick={() => handleLikeIncrement(t, index)} />{" "}
                  {t.likes}
                </span>
              )} */}
              <h5 style={{ padding: 20 }}>{t.service}</h5>
            </div>
          )}
          {!t.brand && <h4>{t.name}</h4>}
          <h6>
            {t.city && <span>{t.city},</span>} {t.country}
          </h6>
          {user && (
            <button onClick={() => toggleFavs(t.k)} className="btn btn-light">
              <FaHeart color={favIds.includes(t.k) ? "red" : "grey"} />
            </button>
          )}
          <div style={{ marginTop: 20 }}>
            {t.website && (
              <h6>
                <a href={t.website} target="_blank" >
                  <FaDesktop /> visit website
                </a>
              </h6>
            )}
            {t.email && (
              <h6>
                <a href={`mailto:${t.email}`}>
                  <FaEnvelope /> {t.email}
                </a>
              </h6>
            )}
            {t.tel && (
              <h6>
                <a href={`tel:${t.tel}`}>
                  <FaPhoneAlt /> {t.tel}
                </a>
              </h6>
            )}
          </div>
        </div>

        <hr />
        <div style={{ padding: 20 }}>
          <p>{t.story}</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{ marginBottom: 20 }}
            className="btn btn-light btn-sm"
            onClick={close}
          >
            close
          </button>
          {user && !reviewMode && (
            <button
              style={{ marginBottom: 20 }}
              className="btn btn-primary btn-sm"
              onClick={() => setReviewMode(true)}
            >
              write a review
            </button>
          )}
          {user && (
            <a
              style={{
                marginBottom: 20,
                marginLeft: 10,
                cursor: "pointer",
                color: "rgb(45, 140, 140)",
              }}
              href={`mailto:?subject=I${t.brand}%20by%20${t.name}&body=Hi!%0D%0DI%20thought%20you%20might%20be%20interested%20in%20this%20great%20enterprise%20I%20discovered%20on%20www.ossianet.com%2E%0D%0DCheck%20out%20${t.name}%27s%20activites%20at%20${t.website}%2E%0D%0Dbest%20wishes%2C%0D${user.displayName}`}
            >
              <FaShareAlt />
            </a>
          )}
        </div>
      </div>

      <div className={reviewMode ? "review-composer open" : "review-composer"}>
        <hr />
        <div style={{ padding: 10 }}>
          <h5>Write your review here</h5>{" "}
          <button
            className="btn btn-light btn-sm"
            onClick={() => setReviewMode(false)}
          >
            cancel
          </button>
          <button className="btn btn-primary btn-sm" onClick={submitReview}>
            post
          </button>
          <textarea
            value={reviewText}
            onChange={handleReviewInput}
            className="form-control"
            rows={14}
          />
        </div>
      </div>

      <div>
        {reviews.length > 0 && (
          <h6 style={{ textAlign: "center" }}>Customer Reviews</h6>
        )}
        {reviews.length > 0 &&
          reviews.map((review, index) => {
            const content = marked(sanitizeHtml(review.content));
            return (
              <div className="review-panel">
                <div className="review-meta">
                  <div>
                    <span>
                      posted by {review.by}
                      <br />
                      <small>{moment(review.submitted).fromNow()}</small>
                    </span>
                  </div>
                  {user && user.email === review.email && (
                    <div>
                      <FaTimesCircle
                        onClick={() => setPostDeleteMode(true)}
                        style={{
                          marginTop: 5,
                          cursor: "pointer",
                          float: "right",
                        }}
                        color="darkred"
                      />
                      <div
                        className={
                          postDeleteMode
                            ? "delete-review open"
                            : "delete-review"
                        }
                      >
                        <button
                          onClick={() => deleteReview(review, index)}
                          className="btn btn-danger btn-sm"
                        >
                          confirm delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <section
                  style={{ padding: 20 }}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default DetailsModal;
