import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {  FaQuestion } from 'react-icons/fa'

const Tiles = ({traders, luckyDip, recent, filter}) => {

const cats = traders.map(t => t.category)
const uniqueCats = [...new Set(cats)]

const array = [...traders]
const recentlyAdded = array.filter(t => t.submitted)
const recentInOrder = recentlyAdded.reverse().slice(0,4).sort((a, b) => {
    if (a.submitted > b.submitted) return -1;
    if (a.submitted < b.submitted) return 1;
    return 0;
  })

    return (
        <div className="tiles" >
<Row>
    <Col xs={6} md={4} lg={3} >
    <div className="tile" onClick={luckyDip} style={{
        backgroundImage:'url("https://images.unsplash.com/photo-1518688248740-7c31f1a945c4?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8bG90dGVyeXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60")',
        backgroundSize:'cover',
        backgroundPosition:'center'
    }} >
        <div className="tile-text" >
        <FaQuestion className="question"  size={45}/>

        <h5>Lucky Dip</h5>
        <p>Show 1 Random Card</p>
        </div>
        </div>
    </Col>
    <Col xs={6} md={4} lg={3} >
    <div className="tile" onClick={recent} style={{
        backgroundImage:'url("https://images.unsplash.com/photo-1543599538-a6c4f6cc5c05?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8d2VsY29tZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60")',
        backgroundSize:'cover',
        backgroundPosition:'top'
    }} >
        <div className="recent-tile-text" >
      

<h5>Recently Added ({recentInOrder.length})</h5>

        </div>
        </div>
    </Col>
    {uniqueCats.sort().map(c => {
        let image;
        if(c === "Food & Drink") { image = "https://images.unsplash.com/photo-1467453678174-768ec283a940?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjF8fGZvb2QlMjBhbmQlMjBkcmlua3xlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Websites") { image = "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDl8fHdlYnNpdGVzfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Artwork") { image = "https://images.unsplash.com/photo-1565876427310-0695a4ff03b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8YXJ0JTIwZ2FsbGVyeXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Gifts") { image = "https://images.unsplash.com/photo-1606328252399-393f9bb6cad4?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8Z2lmdHN8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Jewellery") { image = "https://images.unsplash.com/photo-1523251691580-613e117a75e7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTF8fGpld2VsbGVyeXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Tuition (Music)") { image = "https://images.unsplash.com/photo-1507838153414-b4b713384a76?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8bXVzaWMlMjBzY29yZXxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=700&q=60"}
        if(c === "Tuition (Other)") { image = "https://images.unsplash.com/photo-1584697964358-3e14ca57658b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTR8fGxlYXJuaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Tuition (Languages") { image = "https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjV8fGxhbmd1YWdlJTIwdHVpdGlvbnxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Building & home improvement") { image = "https://images.unsplash.com/photo-1606676539940-12768ce0e762?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fGhvbWUlMjBpbXByb3ZlbWVudHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Administration / office work") { image = "https://images.unsplash.com/photo-1551434678-e076c223a692?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8b2ZmaWNlJTIwd29ya2VyfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Accountancy") { image = "https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8YWNjb3VudGFudHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Health & Fitness") { image = "https://images.unsplash.com/photo-1477332552946-cfb384aeaf1c?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjB8fGhlYWx0aCUyMGFuZCUyMGZpdG5lc3N8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Photography") { image = "https://images.unsplash.com/photo-1513031300226-c8fb12de9ade?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cGhvdG9ncmFwaGVyfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Media creation (video / audio editing)") { image = "https://images.unsplash.com/photo-1520904738498-88ba7af0ecd7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzB8fG1lZGlhJTIwY3JlYXRpb258ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Travel") { image = "https://images.unsplash.com/photo-1488085061387-422e29b40080?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OHx8dHJhdmVsfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Other") { image = "https://images.unsplash.com/photo-1528642474498-1af0c17fd8c3?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVvcGxlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Clothes") { image = "https://images.unsplash.com/photo-1524224971825-8c690dec4b7c?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8dGFpbG9yfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"}
        if(c === "Handmade") { image = "https://images.unsplash.com/photo-1516266967839-c9ab062fabc1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTh8fGhhbmRtYWRlJTIwY3JhZnRzfGVufDB8fDB8&auto=format&fit=crop&w=700&q=60"}
        if(c === "Translations") { image = "https://unsplash.com/photos/jhtPCeQ2mMU"}
        if(c === "Budgeting & Finance") { image = "https://images.unsplash.com/photo-1521897258701-21e2a01f5e8b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8YnVkZ2V0aW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"}
        if(c ===  "Garden & Outdoor items"
        ) { image = "https://images.unsplash.com/photo-1577711457679-8e096abef7b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mzl8fGdhcmRlbmluZyUyMGZ1cm5pdHVyZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"}
        
        let group = traders.reverse().filter(t => t.category === c)
        // console.log(group);

    
    return <Col xs={6} md={4} lg={3} >
    <div className="tile" 
    style={{backgroundImage: ` url(${image})`,
    backgroundSize:'cover',
    backgroundPosition:'center'

    }} onClick={() => filter(c)} >
        <div className="tile-text" >
        <h5>{c} ({group.length})</h5>
        </div>
        </div>
    </Col>
    }
    )}
    </Row>

        </div>
    )
}

export default Tiles
