import React from 'react'
import {
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
   
  } from "react-share";
  
const SharingButtons = () => {
    return (
        <div style={{margin:40}} >       
                        <p style={{textAlign:'center'}}>Share this page</p><br></br>

             <div className="sharing-buttons">
          
             <WhatsappShareButton
             className="icon"
             url={window.location}
             children={<WhatsappIcon size={50} round={true}  />}
             />
         
              <TwitterShareButton
              className="icon"
                url={window.location}
                children={<TwitterIcon round={true} size={50} />}
              />
         
              <FacebookShareButton
              className="icon"
                url={window.location}
                children={<FacebookIcon round={true} size={50} />}
              />
                   <LinkedinShareButton
                   className="icon"
                url={window.location}
                children={<LinkedinIcon round={true} size={50} />}
              />
            
</div>

            </div>  
    )
}

export default SharingButtons
