import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  ThemeProvider
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(32, 164, 164)',
    },
    secondary: {
      main: 'rgb(220, 0, 0)',
    },
  },
});

const useStyles = makeStyles((theme) => ({
    customSelect:{ 
        color:'rgb(32, 164, 164)' 
    },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    
        "& label.Mui-focused": {
          color: "rgb(32, 164, 164)",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "rgb(32, 164, 164)",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "rgb(32, 164, 164)",
          },
          "&:hover fieldset": {
            borderColor: "rgb(32, 164, 164)",
            borderWidth:2
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgb(32, 164, 164)",
          },
        
      },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  
}));
const CustomSelect = ({ onchange, value, options, traders, label }) => {
  const classes = useStyles();

  return (
      <ThemeProvider theme={theme} >
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.customSelect}>{label}</InputLabel>
      <Select
        className={classes.customSelect}
        onChange={onchange}
        value={value}
      >
        <MenuItem value="all">All</MenuItem>
        {options.map((c) => {
          const array = [...traders];
          const numbers = array.filter((t) => t.category === c);
          return (
            <MenuItem value={c}>
              {c} {numbers.length > 0 && <span>: {numbers.length}</span>}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
    </ThemeProvider>
  );
};

export default CustomSelect;
